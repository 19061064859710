import { Component, ViewChild,ElementRef, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnimationOptions } from 'ngx-lottie';
import { HeaderInterceptor } from 'src/app/services/header.interceptor';
import { KycService } from './kyc.service';

export class kycModel{
  mobile_number: string = '';
  adhaar_number: string = '';
  ref_id: string = '';
  otp: string = '';
  pan: string = '';
  name: string = '';
  constructor() {
    this.mobile_number = '';
    this.adhaar_number = '';
    this.ref_id = '';
    this.otp = '';
    this.pan = '';
    this.name='';
  }
}


@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.css']
})





export class KycComponent implements OnInit {
  @ViewChild('videoElement', { static: true }) videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement', { static: true }) canvasElement!: ElementRef<HTMLCanvasElement>;
  @ViewChild('verification_loading', { static: true }) verification_loading:| TemplateRef<any> | undefined;
  options: AnimationOptions = {
      path: '/assets/lottie/kyc_success.json'
    };
  kyc_data = new kycModel();

  photoUrl: string | null = null; // Captured photo as base64 string
  constructor(  private modalService: NgbModal,private router: Router,private kycService:KycService) { }
  PAGE_NAME="Kyc Verification";
  private activeUI: string = 'list'; // Default active UI

  ngOnInit(): void {
    let token=HeaderInterceptor.getAccessToken()
    console.log("token ",token);
    if(token==null){
      this.router.navigate(['/login'], { queryParams: { kyc: 'true' } });

    }
  }
  proceed()
  {
    if(this.isActiveUI('adhaar_verification'))
      {
        this.kycService.reqAdhaarOtp(this.kyc_data).subscribe((res:any)=>{
          console.log("res ", res);
          this.kyc_data.ref_id=res.ref_id;
        })
      }
    if(this.isActiveUI('otp_block'))
    {
      this.kycService.verifyAdhaar(this.kyc_data).subscribe((res:any)=>{
        console.log("res ", res);
        this.openLoading(this.verification_loading);
      },
      (error:any)=>
        {
          this.openLoading(this.verification_loading);
    
        })
  
    }
    if(this.isActiveUI('pan_verification')){
      this.kycService.verifyPan(this.kyc_data).subscribe((res:any)=>{
        console.log("res ", res);
        this.openLoading(this.verification_loading);
    },
    (error:any)=>
      {
        this.openLoading(this.verification_loading);
  
      })
    }
  
  }
  onOtpChange(value: any) {
    if (value.length == 6) {
      this.kyc_data.otp = value;
    }
   

  }
  setActiveUI(uiId: string): void {
    if(uiId=='live_photo')
    {
      this.startCamera();
    }
    this.activeUI = uiId;
  }
  isActiveUI(uiId: string): boolean {
    return this.activeUI === uiId;
  }

    /** Access the front camera and stream video to the <video> element */
    startCamera(): void {
      navigator.mediaDevices
        .getUserMedia({
          video: { facingMode: 'user' }, // Use 'user' to access the front camera
        })
        .then((stream) => {
          this.videoElement.nativeElement.srcObject = stream;
          this.videoElement.nativeElement.play();
        })
        .catch((error) => {
          console.error('Error accessing camera:', error);
          alert('Could not access the camera. Make sure permissions are granted.');
        });
    }
  
    /** Capture a frame from the video stream */
    capturePhoto(): void {
      this.setActiveUI('upload_document');
      const video = this.videoElement.nativeElement;
      const canvas = this.canvasElement.nativeElement;
  
      // Set canvas dimensions to match video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
  
      // Draw the current video frame onto the canvas
      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
        // Convert the canvas to a base64 image
        this.photoUrl = canvas.toDataURL('image/png');
      }
    }
    openLoading(content:any){
      this.modalService.open(content, { centered: true ,backdrop:true});
    }
}


