import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { kycModel } from './kyc.component';

@Injectable({
  providedIn: 'root'
})
export class KycService {

  constructor(private http: HttpClient) { }

  verifyAdhaar(data:kycModel){
    // const jsonData = {
    //   "ref_id": data.ref_id,
    //   "otp": data.otp
    // }
    // console.log(jsonData);
    return this.http.post('http://65.1.227.246:8005/organization-service/verify-adhar-otp/',data)
  }
  reqAdhaarOtp(kyc_data:kycModel)
  {
    return this.http.get(`http://65.1.227.246:8005/organization-service/request-adhar-otp?adhar_number=${kyc_data.adhaar_number}`)
  }
  verifyPan(data:kycModel){
    return this.http.post('http://65.1.227.246:8005/organization-service/verify-pan/',data) 
  }
}
